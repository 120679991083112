import React from 'react'

export const NotificationsContext = React.createContext()

const NotificationsProvider = ({
    dispatcher,
    ...props
}) => (
    <NotificationsContext.Provider
        value={dispatcher}
        {...props}
    />
)

export default NotificationsProvider
