import { omitEmptyValues } from '@connections/utils'
import Header from './stories/Header'
import Footer from './stories/Footer'
import Settings from './stories/Settings'
import Page from './stories/Page'
import Tour from './stories/Tour'
import defaultStoryTransformer from './transformStories'

const TRANSFORMERS = {
    Header,
    Footer,
    Settings,
    Page,
    Tour,
}

const transformStory = (story, params) => {
    const transformer = TRANSFORMERS[story.content.component]
    if (transformer) {
        return omitEmptyValues(transformer(story, params))
    }
    return defaultStoryTransformer(story, params)
}

export default function transformData(data, params) {
    if (data.story) {
        return {
            ...data,
            story: transformStory(data.story, params)
        }
    }
    return data
}
