import { useQuery } from 'react-query'
import useGraphqlClient from './useGraphqlClient'

// This is called useGraphqlQuery but it also works with mutations
const useGraphqlQuery = (key, query, params = {}, options = {}) => {
    const graphqlClient = useGraphqlClient()
    return useQuery(
        [key, params],
        () => graphqlClient.request(query, params),
        {
            retry: false,
            refetchOnWindowFocus: false,
            ...options,
        },
    )
}

export default useGraphqlQuery
