import { omitEmptyValues } from '@connections/utils'

const DayByDayPoint = ({
    _uid,
    name,
    title,
    location,
    component,
    isLayover,
    _editable,
}) => omitEmptyValues({
    _uid,
    name,
    title,
    latitude: parseFloat(location?.latitude || 0),
    longitude: parseFloat(location?.longitude || 0),
    component,
    isLayover,
    _editable,
})

export default DayByDayPoint
