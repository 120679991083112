import React, { forwardRef } from 'react'
import clsx from 'clsx'
import { variant as createVariant } from '../../util/index'
import Icon from './Icon'
import Spinner from './Spinner'
import usePageProps from '../hooks/usePageProps'

export const VARIANT_BLUE_LINK = 'link'
export const VARIANT_BLUE_FILLED = 'blue-filled'
export const VARIANT_BLUE_OUTLINED = 'blue-outlined'
export const VARIANT_PRIMARY_FILLED = 'primary-filled'
export const VARIANT_PRIMARY_OUTLINED = 'primary-outlined'
export const VARIANT_WHITE_FILLED = 'white-filled'
export const VARIANT_WHITE_OUTLINED = 'white-outlined'
export const VARIANT_NO_BACKGROUND = 'no-background'
export const VARIANT_WHITE_NO_BACKGROUND = 'white-no-background'
export const VARIANT_OUTLINED = 'outlined'

export const SIZE_LARGE = 'large'
export const SIZE_SMALL = 'small'
export const SIZE_SMALLER = 'smaller'
export const SIZE_SMALLEST = 'smallest'
export const SIZE_LINK = 'link'

const WIDTH_AUTO = 'auto'
const WIDTH_FULL = 'full'

const ROUNDING_ALL = 'all'
const ROUNDING_LEFT = 'left'
const ROUNDING_RIGHT = 'right'
const ROUNDING_TOP = 'top'
const ROUNDING_BOTTOM = 'bottom'

const IconBefore = ({
    className,
    size = 'smaller',
    ...props
}) => (
    <Icon
        size={size}
        className={clsx(
            'mr-2 flex-shrink-0',
            className,
        )}
        {...props}
    />
)

const IconAfter = ({
    className,
    size = 'smaller',
    ...props
}) => (
    <Icon
        size={size}
        className={clsx(
            'ml-2 flex-shrink-0',
            className,
        )}
        {...props}
    />
)

const Button = forwardRef(({
    as = 'button',
    size = SIZE_SMALL,
    rounding = 'all',
    variant,
    width,
    iconAfterName,
    iconBeforeName,
    className,
    children,
    isLoading,
    onClick,
    disabled,
    iconSize,
    ...props
}, ref) => {
    const Component = as
    const pageProps = usePageProps()
    const isPreview = pageProps?.isPreview
    const sizeForIcon = iconSize || size === SIZE_LARGE ? 'smaller' : 'small'
    return (
        <Component
            ref={ref}
            type={as === 'button' ? 'button' : undefined}
            disabled={disabled || isLoading}
            onClick={(e) => {
                if (isPreview) {
                    e.preventDefault()
                } else if (typeof onClick === 'function') {
                    onClick(e)
                }
            }}
            className={clsx(
                'inline-flex items-center justify-center border transition-all font-medium cursor-pointer',
                'disabled:cursor-not-allowed disabled:opacity-60',
                createVariant(width, {
                    [WIDTH_AUTO]: 'w-auto',
                    [WIDTH_FULL]: 'w-full',
                }),
                createVariant(rounding, {
                    [ROUNDING_ALL]: 'rounded-md',
                    [ROUNDING_TOP]: 'rounded-t-md',
                    [ROUNDING_BOTTOM]: 'rounded-b-md',
                    [ROUNDING_LEFT]: 'rounded-l-md',
                    [ROUNDING_RIGHT]: 'rounded-r-md',
                }),
                createVariant(size, {
                    [SIZE_LINK]: 'text-sm py-2',
                    [SIZE_SMALLER]: 'text-sm px-3 py-2 leading-[18px]',
                    [SIZE_SMALLEST]: 'text-xs px-2 py-1',
                    [SIZE_SMALL]: 'text-sm px-4 py-3',
                    [SIZE_LARGE]: 'text-base px-5 py-3 leading-[22px]',
                }),
                createVariant(variant, {
                    [VARIANT_BLUE_LINK]: 'border-transparent hover:underline disabled:no-underline text-blue-bright underline-offset-4',
                    [VARIANT_BLUE_FILLED]: 'bg-blue-bright border-blue-bright text-white hover:bg-blue hover:border-blue',
                    [VARIANT_BLUE_OUTLINED]: 'bg-transparent border-blue-bright text-blue-bright hover:bg-blue-bright hover:text-white',
                    [VARIANT_PRIMARY_FILLED]: 'bg-primary border-primary text-white hover:bg-primary-dark hover:border-primary-dark',
                    [VARIANT_PRIMARY_OUTLINED]: 'bg-transparent border-primary hover:bg-primary text-primary hover:text-white',
                    [VARIANT_WHITE_FILLED]: 'bg-white border-white text-blue-bright',
                    [VARIANT_WHITE_OUTLINED]: 'bg-transparent border-white text-white hover:bg-white hover:text-blue-bright group-hover:bg-white group-hover:text-blue-bright',
                    [VARIANT_NO_BACKGROUND]: 'bg-transparent border-transparent',
                    [VARIANT_WHITE_NO_BACKGROUND]: 'text-white bg-transparent border-transparent hover:underline group-hover:underline',
                    [VARIANT_OUTLINED]: 'bg-transparent border-gray-100 text-white hover:bg-gray-100 hover:text-black disabled:opacity-50',
                }),
                className,
            )}
            {...props}
        >
            {isLoading && (
                <Spinner
                    size={sizeForIcon}
                    className="mr-2 flex-shrink-0"
                />
            )}
            {!isLoading && iconBeforeName && (
                <IconBefore
                    size={sizeForIcon}
                    name={iconBeforeName}
                />
            )}
            {children}
            {iconAfterName && (
                <IconAfter
                    size={sizeForIcon}
                    name={iconAfterName}
                />
            )}
        </Component>
    )
})

export default Button
