import { getHrefFromLink } from '../../../util'

const SocialLink = ({
    _uid,
    link,
    iconName,
}, params) => ({
    _uid,
    href: getHrefFromLink(link, params.language),
    iconName,
})

export default SocialLink
