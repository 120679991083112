import { omitEmptyValues } from '@connections/utils'
import DayItem from '../components/DayItem'
import componentTransformer from '../transformComponent'
import defaultStoryTransformer from '../transformStories'

export default function Tour(story, params) {
    const {
        dayItems,
        body,
    } = story.content
    return {
        ...defaultStoryTransformer(story, params),
        content: omitEmptyValues({
            ...story.content,
            dayItems: dayItems.map((item) => DayItem(item, params)),
            body: body.map((component) => componentTransformer(component, params))
        })
    }
}
