import { omitEmptyValues } from '@connections/utils'

const FeaturesSectionImage = ({
    name,
    image,
    _editable,
}) => omitEmptyValues({
    name,
    image: omitEmptyValues({
        alt: image.alt || null,
        filename: image.filename,
    }),
    _editable,
})

export default FeaturesSectionImage
