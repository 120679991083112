import { omitEmptyValues } from '@connections/utils'
import Image from './Image'
import DayItemHotel from './DayItemHotel'
import DayByDayPoint from './DayByDayPoint'
import DayItemExcursion from './DayItemExcursion'

const DayItem = ({
    _uid,
    name,
    image,
    title,
    hotels = [],
    points = [],
    component,
    excursions = [],
    description,
    shortDescription,
    dayItemTabGroup,
    _editable,
}, params) => omitEmptyValues({
    _uid,
    name,
    image: Image(image),
    title,
    hotels: hotels.map(DayItemHotel),
    points: points.map(DayByDayPoint),
    component,
    excursions: excursions.map((excursion) => DayItemExcursion(excursion, params)),
    description,
    shortDescription,
    dayItemTabGroup,
    _editable,
})

export default DayItem
