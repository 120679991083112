import { omitEmptyValues } from '@connections/utils'

const IconFeatureCard = ({
    icon,
    text,
    title,
    description,
    _uid,
    _editable,
}) => omitEmptyValues({
    icon,
    text,
    title,
    description,
    _uid,
    _editable,
})

export default IconFeatureCard
