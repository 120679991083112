import { omitEmptyValues } from '@connections/utils'

const DayItemHotel = ({
    _uid,
    name,
    description,
    hotel,
    _editable
}) => omitEmptyValues({
    name: hotel?.content?.name || name,
    description: hotel?.content?.description || description,
    fullSlug: hotel?.full_slug,
    _uid,
    _editable,
})

export default DayItemHotel
