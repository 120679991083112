import { omitEmptyValues } from '@connections/utils'
import Image from './Image'

const Heading = ({
    _uid,
    image,
    title,
    video,
    entity,
    buttons,
    gradient,
    gradientMobile,
    position,
    subTitle,
    component,
    container,
    background,
    titleColor,
    description,
    centerMobile,
    contentImage,
    subTitleColor,
    videoEndOffset,
    descriptionColor,
    fullHeightMobile,
    videoStartOffset,
    contentImageMaxWidth,
    contentImageMaxHeight,
    _editable,
}) => omitEmptyValues({
    _uid,
    image,
    title,
    video,
    entity,
    buttons,
    gradient,
    gradientMobile,
    position,
    subTitle,
    component,
    container,
    background,
    titleColor,
    description,
    centerMobile,
    contentImage: contentImage && Image(contentImage),
    subTitleColor,
    videoEndOffset,
    descriptionColor,
    fullHeightMobile,
    videoStartOffset,
    contentImageMaxWidth,
    contentImageMaxHeight,
    _editable,
})

export default Heading
