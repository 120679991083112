import { omitEmptyValues } from '@connections/utils'
import FeaturedCarouselSlide from './FeaturedCarouselSlide'

const FeaturedCarouselSection = ({
    title,
    subtitle,
    slides,
    spacing,
    anchorId,
    component,
    size,
    background,
    initialSlideIndex,
    horizontalSpacing,
    verticalSpacing,
    hasSkew,
    hasArrows,
    hasDots,
    autoplaySpeed,
    _editable,
    _uid,
}) => omitEmptyValues({
    title,
    subtitle,
    slides: slides.map(FeaturedCarouselSlide),
    spacing,
    anchorId,
    component,
    size,
    background,
    initialSlideIndex,
    horizontalSpacing,
    verticalSpacing,
    hasSkew,
    hasArrows,
    hasDots,
    autoplaySpeed,
    _editable,
    _uid,
})

export default FeaturedCarouselSection
