import Card from './Card'

const CardsSection = ({
    _uid,
    _editable,
    cards,
    links,
    title,
    columns,
    spacing,
    anchorId,
    component,
    container,
    background,
    isCarousel,
    spacingTop,
    description,
    spacingBottom,
}) => ({
    _uid,
    _editable,
    cards: cards.map(Card),
    links,
    title,
    columns,
    spacing,
    anchorId,
    component,
    container,
    background,
    isCarousel,
    spacingTop,
    description,
    spacingBottom,
})

export default CardsSection
