import { omitEmptyValues } from '@connections/utils'
import Image from './Image'

const ProgramBlock = ({
    uuid,
    content = {},
}) => {
    const {
        image,
        title,
        description,
        button,
    } = content

    return omitEmptyValues({
        uuid,
        title,
        description,
        image: image ? Image(image) : null,
        button: button?.length === 1 ? button[0] : null,
    })
}

export default ProgramBlock
