import MapColumn from './MapColumn'

const Column = ({
    ...content
}) => {
    switch (content.component) {
        case 'MapColumn':
            return MapColumn(content)
        default:
            return {
                ...content
            }
    }
}

export default Column
