import { omitEmptyValues } from '@connections/utils'
import Page from './Page'

const getEntityValues = (entity = null) => {
    if (entity === null) {
        return null
    }
    const {
        content: {
            title,
            description,
            headImage,
        } = {},
        full_slug,
    } = entity

    return {
        content: omitEmptyValues({
            title,
            description,
            headImage,
        }),
        full_slug: full_slug || '',
    }
}

const HeaderCard = ({
    tour,
    break: breakItem,
    activity,
    destination,
    hideGradient,
    page,
    link,
    image,
    title,
    description,
    ...other
}) => (
    omitEmptyValues({
        tour: getEntityValues(tour),
        breakItem: getEntityValues(breakItem),
        activity: getEntityValues(activity),
        destination: getEntityValues(destination),
        page: Page(page),
        hideGradient,
        link, //@TODO: replace link with href
        image,
        title,
        description,
        ...other,
    })
)

export default HeaderCard
