import { omitEmptyValues } from '@connections/utils'
import CardsSection from "./components/CardsSection";
import ProgramSection from "./components/ProgramSection";
import HeadingSection from "./components/HeadingSection";
import ColumnsSection from "./components/ColumnsSection";
import DayByDaySection from "./components/DayByDaySection";
import FeaturesSection from "./components/FeaturesSection";
import DayByDayMapSection from "./components/DayByDayMapSection";
import TestimonialsSection from "./components/TestimonialsSection";
import ProgramBlocksSection from "./components/ProgramBlocksSection";
import FeaturedCarouselSection from "./components/FeaturedCarouselSection";
import FlightsListerGroupSection from "./components/FlightsListerGroupSection";
import ReviewsSection from "./components/ReviewsSectionTransformer";
import Component from "./components/ComponentTransformer";

const OLD_TRANSFORMERS = {
    CardsSection,
    ColumnsSection,
    ProgramSection,
    HeadingSection,
    DayByDaySection,
    FeaturesSection,
    DayByDayMapSection,
    TestimonialsSection,
    ProgramBlocksSection,
    FeaturedCarouselSection,
    FlightsListerGroupSection,
};

const TRANSFORMERS = {
    ReviewsSection,
};

export default function transformComponent(content: any, context: any) {
    if (!content || !context) return null;
    const { component } = content;
    if (component) {
        const OldTransformer = OLD_TRANSFORMERS[component];
        if (OldTransformer) {
            return omitEmptyValues(OldTransformer(content, context));
        }
        const Transformer = TRANSFORMERS[component];
        if (Transformer) {
            return omitEmptyValues(Transformer.transform(content, context));
        }
    }

    return content;
}
