import { omitEmptyValues } from '@connections/utils'
import { getHrefFromLink } from '../../../util'
import HeaderMenuItemDropdown from './HeaderMenuItemDropdown'

const HeaderMenuItem = ({
    _uid,
    text,
    link,
    dropdowns,
    hasDropdown,
}, params) => {
    const hasLink = link && (link.story || link.url)
    const href = hasLink
        ? getHrefFromLink(link, params.language)
        : null

    return omitEmptyValues({
        _uid,
        text,
        href,
        hasDropdown,
        openInNewTab: link.target === '_blank',
        linkStoryComponent: link.story?.content?.component,
        dropdowns: dropdowns.map((item) => HeaderMenuItemDropdown(item, params)),
    })
}

export default HeaderMenuItem
