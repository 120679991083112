import { omitEmptyValues } from '@connections/utils'
import Heading from './Heading'

const HeadingSection = ({
    _uid,
    size,
    hasArc,
    maxSize,
    anchorId,
    hasNoQsm,
    headings,
    component,
    hasInterval,
    _editable,
}) => omitEmptyValues({
    _uid,
    size,
    hasArc,
    maxSize,
    anchorId,
    hasNoQsm,
    headings: headings.map(Heading),
    component,
    hasInterval,
    _editable,
})

export default HeadingSection
