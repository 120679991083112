import FlightsListerGroup from './FlightsListerGroup'

const FlightsListerGroupSection = ({
    _uid,
    _editable,
    component,
    title,
    groups,
    airline,
    buttons,
    spacing,
    variant,
    anchorId,
    container,
    background,
    description,
}) => ({
    _uid,
    _editable,
    component,
    title,
    groups: groups.map(FlightsListerGroup),
    airline,
    buttons,
    spacing,
    variant,
    anchorId,
    container,
    background,
    description,
})

export default FlightsListerGroupSection
