import componentTransformer from './transformComponent'

export default function defaultStoryTransformer({
    translated_slugs,
    default_full_slug,
    content,
    lang,
    full_slug,
}, params) {
    const defaultSlug = {
        lang: 'nl',
        path: default_full_slug,
    }
    const translatedSlugs = [defaultSlug, ...translated_slugs]
    let { body } = content
    body = typeof body !== 'undefined'
        ? body.map((component) => componentTransformer(component, params))
        : null

    return {
        content: {
            ...content,
            body,
        },
        lang,
        full_slug,
        translatedSlugs,
    }
}
