import { omitEmptyValues } from '@connections/utils'
import ProgramSlot from './ProgramSlot'

const ProgramGroup = ({
    uuid,
    content: {
        _uid,
        slots = [],
        title,
        component,
        _editable,
    },
}) => omitEmptyValues({
    uuid,
    _uid,
    slots: slots.map(ProgramSlot),
    title,
    component,
    _editable,
})

export default ProgramGroup
