import componentTransformer from '../transformComponent'
import defaultStoryTransformer from '../transformStories'

export default function Page(story, context) {
    const { content: { body } } = story

    return {
        ...defaultStoryTransformer(story),
        content: {
            ...story.content,
            body: body.map((component) => componentTransformer(component, context))
        }
    }
}
