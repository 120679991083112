import { omitEmptyValues } from '@connections/utils'
import { getHrefFromLink } from '../../../util'

const Link = ({
    _uid,
    link,
    text,
    openInNewTab,
    iconAfterName,
    iconBeforeName,
}, params) => omitEmptyValues({
    _uid,
    href: getHrefFromLink(link, params.language),
    text,
    openInNewTab,
    iconAfterName,
    iconBeforeName,
})

export default Link
