import { Asset, Component } from "../../storyblokTypes";
import transformAsset from "../transformAsset";
import ComponentTransformer from "./ComponentTransformer";

export type ReviewsSection = Component & {
    title: string;
    spacing: string;
    background: string;
    container: string;
};

export default class ReviewsSectionTransformer extends ComponentTransformer {
    static transform(content: any, context: any) {
        const { title, description, spacing, background, container, align } =
            content;

        return {
            ...super.transform(content, context),
            title,
            spacing,
            background,
            container,
        };
    }
}
