import React from 'react'
import clsx from 'clsx'
import { Trans } from '@lingui/react'
import Icon from './Icon'

const ModalCloseButton = ({
    onClose,
    hasText,
    color,
    className,
    ...props
}) => (
    <button
        type="button"
        className={clsx(
            'absolute py-6 px-4 top-0 right-0',
            className,
        )}
        {...props}
    >
        {hasText && (
            <Trans id="Close" />
        )}
        <Icon
            name="cross"
            size="smaller"
            color={color}
        />
    </button>

)

export default ModalCloseButton
