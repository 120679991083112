import { omitEmptyValues } from '@connections/utils'

const Image = ({
    filename,
    alt,
}) => omitEmptyValues({
    src: filename,
    alt,
})

export default Image
