import { omitEmptyValues } from '@connections/utils'
import FeaturesSectionImage from './FeaturesSectionImage'
import IconFeatureCard from './IconFeatureCard'

const FeaturesSection = ({
    _uid,
    background,
    spacing,
    container,
    anchorId,
    title,
    arrowText,
    component,
    description,
    featureImages = [],
    iconFeatureCards = [],
    _editable,
}) => omitEmptyValues({
    _uid,
    background,
    spacing,
    container,
    anchorId,
    title,
    arrowText,
    component,
    description,
    featureImages: featureImages.map(FeaturesSectionImage),
    iconFeatureCards: iconFeatureCards.map(IconFeatureCard),
    _editable,
})

export default FeaturesSection
