import { getSimpleLocale } from '@connections/utils'
import { LANGUAGE_EN } from '@connections/constants'

export const isUnauthenticatedError = (error) => (
    error.extensions && error.extensions.code === 'UNAUTHORIZED_ERROR'
)

export const mergeQueryResults = (previousResult, fetchMoreResult, path) => {
    const {
        edges: newEdges,
        totalCount,
        pageInfo,
    } = fetchMoreResult[path]

    return newEdges.length ? {
        [path]: {
            // eslint-disable-next-line no-underscore-dangle
            __typename: previousResult[path].__typename,
            edges: [...previousResult[path].edges, ...newEdges],
            pageInfo,
            totalCount,
        },
    } : previousResult
}

export const connectionToCollection = (connection = { edges: [] }) => (
    connection.edges.map((edge) => edge.node)
)

export const getMessageFromGraphqlError = (error) => error?.response?.errors?.[0]?.message

export const callApi = async (query, variables) => {
    const body = JSON.stringify({ query, variables })
    const response = await fetch(process.env.NEXT_PUBLIC_GRAPHQL_URI, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            locale: getSimpleLocale(LANGUAGE_EN),
        },
        body,
    })
    return response.json()
}
