import Destination from './FlightListerGroupDestination'

const FlightsListerGroup = ({
    _uid,
    component,
    title,
    destinations,
}) => ({
    _uid,
    component,
    title,
    destinations: destinations.map(Destination),
})

export default FlightsListerGroup
