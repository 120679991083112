import Link from '../components/Link'
import LinkGroup from '../components/LinkGroup'
import SocialLink from '../components/SocialLink'

const Footer = ({
    content: {
        openingHours,
        address,
        linkGroups,
        socialLinks,
        disclaimerLinks,
    }
}, params) => ({
    openingHours,
    address,
    linkGroups: linkGroups.map((group) => LinkGroup(group, params)),
    socialLinks: socialLinks.map((socialLink) => SocialLink(socialLink, params)),
    disclaimerLinks: disclaimerLinks.map((item) => Link(item, params)),
})

export default Footer
