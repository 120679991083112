import StoryblokClient from './client/index'

function createStoryblokClient(isPreview) {
    const accessToken = isPreview
        ? process.env.NEXT_PUBLIC_STORYBLOK_PREVIEW_TOKEN
        : process.env.NEXT_PUBLIC_STORYBLOK_PUBLIC_TOKEN

    return new StoryblokClient({
        accessToken,
        cache: {
            type: 'memory',
            clear: isPreview ? 'auto' : 'manual',
        },
    })
}

let cache = null
let previewCache = null

export default function getStoryblokClient(isPreview) {
    if (isPreview) {
        if (previewCache === null) {
            previewCache = createStoryblokClient(isPreview)
        }
        return previewCache
    }

    if (cache === null) {
        cache = createStoryblokClient(isPreview)
    }
    return cache
}
