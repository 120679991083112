import { omitEmptyValues } from '@connections/utils'
import Testimonial from './Testimonial'

const TestimonialsSection = ({
    _uid,
    _editable,
    title,
    spacing,
    anchorId,
    component,
    background,
    testimonials,
    backgroundImage: {
        filename,
    } = {},
}) => omitEmptyValues({
    _uid,
    _editable,
    title,
    spacing,
    anchorId,
    component,
    background,
    testimonials: testimonials.map(Testimonial),
    backgroundImage: filename,
})

export default TestimonialsSection
