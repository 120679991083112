const MapColumn = ({
    points,
    ...content
}) => ({
    points: points.map(({
        location,
        latitude,
        longitude,
        ...point
    }) => ({
        latitude: location?.latitude || latitude,
        longitude: location?.longitude || longitude,
        ...point,
    })),
    ...content,
})

export default MapColumn
