import { useEffect, useState, useContext } from 'react'
import { useLingui } from '@lingui/react'
import * as constants from '@connections/constants'
import { NotificationsContext } from '../providers/NotificationsProvider'

const {
    NOTIFICATION_TYPE_ERROR: ERROR,
    NOTIFICATION_TYPE_WARNING: WARNING,
    NOTIFICATION_TYPE_SUCCESS: SUCCESS,
} = constants

const useNotifications = () => {
    const { i18n } = useLingui()
    const notificationDispatcher = useContext(NotificationsContext)
    const [notifications, setNotifications] = useState([])
    useEffect(() => (
        notificationDispatcher.subscribe((newNotifications) => {
            setNotifications(newNotifications)
        })
    ), [notificationDispatcher])

    const dispatchSuccess = (data) => (
        notificationDispatcher.dispatch({
            type: SUCCESS,
            message: i18n._('Your request was handled successfully.'),
            ...data,
        })
    )

    const dispatchError = (data = {}) => (
        notificationDispatcher.dispatch({
            type: ERROR,
            message: i18n._('Oops, looks like something went wrong, please try again later.'),
            ...data,
        })
    )

    const dispatchGraphqlError = (error, data) => {
        let message = i18n._('Oops, looks like something went wrong, please try again later.')
        if (error.networkError) {
            message = error.networkError.result.errors[0].message
        }
        if (error.graphQLErrors.length > 0) {
            message = error.graphQLErrors[0].message
        }
        return (
            notificationDispatcher.dispatch({
                type: ERROR,
                message,
                ...data,
            })
        )
    }

    const dispatchWarning = (data) => (
        notificationDispatcher.dispatch({
            type: WARNING,
            message: i18n._('Careful a warning was triggered.'),
            ...data,
        })
    )

    const removeNotification = (notification) => {
        notificationDispatcher.removeNotification(notification)
    }

    return {
        notifications,
        dispatchError,
        dispatchWarning,
        dispatchSuccess,
        dispatchGraphqlError,
        dispatch: (data) => notificationDispatcher.dispatch(data),
        removeNotification,
    }
}

export default useNotifications
