import { omitEmptyValues } from '@connections/utils'

const contentTransformer = ({
    headImage,
    name,
    iataCode,
    airportCodes,
} = {}) => ({
    headImage: headImage ? {
        alt: headImage.alt || null,
        filename: headImage.filename || null,
    } : null,
    name,
    iataCode,
    airportCodes,
})

const flightPriceTransformer = ({
    price,
    airlineCode,
    returnDate,
    departureDate,
}) => ({
    price,
    airlineCode,
    returnDate,
    departureDate,
})

const FlightListerGroupDestination = (destination = null) => {
    if (destination === null) {
        return null
    }
    const {
        uuid,
        id,
        content,
        flightPrice = {},
        full_slug,
    } = destination
    return {
        uuid,
        id,
        content: omitEmptyValues(contentTransformer(content)),
        flightPrice: omitEmptyValues(flightPriceTransformer(flightPrice)),
        full_slug,
    }
}

export default FlightListerGroupDestination
