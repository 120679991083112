import { omitEmptyValues } from '@connections/utils'

const Destination = (destination = null) => {
    if (destination === null || destination === '') {
        return null
    }
    if (typeof destination.content === 'undefined') {
        return destination
    }
    const {
        content: {
            link,
            headImage,
            title,
            description,
        },
        full_slug,
    } = destination
    return {
        content: omitEmptyValues({
            link,
            headImage,
            title,
            description,
        }),
        full_slug,
    }
}

export default Destination
