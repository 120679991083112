import { omitEmptyValues } from '@connections/utils'

const Activity = (activity = null) => {
    if (activity === null || activity === '') {
        return null
    }
    if (typeof activity.content === 'undefined') {
        return activity
    }
    const {
        content: {
            tag,
            href,
            headImage,
            title,
            iataCodes,
            description,
            price,
        },
        full_slug,
    } = activity
    return {
        content: omitEmptyValues({
            tag,
            href,
            headImage,
            title,
            iataCodes,
            description,
            price,
        }),
        full_slug,
    }
}

export default Activity
