import { omitEmptyValues } from '@connections/utils'
import ProgramGroup from './ProgramGroup'

const ProgramBlocksSection = ({
    title,
    groups,
    spacing,
    anchorId,
    component,
    container,
    background,
    description,
    blocksPerPage,
    expandText,
    _uid,
    _editable,
}) => omitEmptyValues({
    title,
    groups: groups.map(ProgramGroup),
    spacing,
    anchorId,
    component,
    container,
    background,
    description,
    blocksPerPage,
    expandText,
    _uid,
    _editable,
})

export default ProgramBlocksSection
