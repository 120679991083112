import { omitEmptyValues } from '@connections/utils'

const Page = (page = null) => {
    if (page === null || page === '') {
        return null
    }
    if (typeof page.content === 'undefined') {
        return page
    }
    const {
        content: {
            seo: {
                title,
                og_image,
                og_title,
                description,
                og_description,
            },
        },
        full_slug,
    } = page
    return {
        content: omitEmptyValues({
            headImage: og_image !== '' && {
                id: '',
                alt: title,
                filename: og_image,
            },
            title: title || og_title,
            description: description || og_description,
        }),
        full_slug,
    }
}

export default Page
