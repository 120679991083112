import { omitEmptyValues } from '@connections/utils'

const Tour = (tour = null) => {
    if (tour === null || tour === '') {
        return null
    }
    if (typeof tour.content === 'undefined') {
        return tour
    }
    const {
        content: {
            tag,
            link,
            headImage,
            title,
            iataCodes,
            description,
            price,
        },
        full_slug,
    } = tour
    return {
        content: omitEmptyValues({
            tag,
            link,
            headImage,
            title,
            iataCodes,
            description,
            price,
        }),
        full_slug,
    }
}

export default Tour
