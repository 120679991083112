import { omitEmptyValues } from '@connections/utils'
import { formatFullSlug } from '../../../util'

const DayItemExcursion = ({
    content,
    full_slug,
}, params) => {
    const {
        title,
        summary,
        _uid,
        _editable,
    } = content
    const {
        language,
    } = params
    return omitEmptyValues({
        title,
        summary,
        path: formatFullSlug(full_slug, { locale: language }),
        _uid,
        _editable,
    })
}

export default DayItemExcursion
