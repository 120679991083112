import { omitEmptyValues } from '@connections/utils'

const Testimonial = ({
    _uid,
    name,
    image: {
        filename,
        alt,
    } = {},
    experience,
    text,
    _editable,
}) => omitEmptyValues({
    _uid,
    name,
    image: omitEmptyValues({
        filename,
        alt,
    }),
    experience,
    text,
    _editable,
})

export default Testimonial
