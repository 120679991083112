import HeaderMenuItem from '../components/HeaderMenuItem'
import Link from '../components/Link'

const Header = ({
    content: {
        announcements,
        contactText,
        menuItems,
        showAnnouncementsOnAllPages,
        topBarMenuItems,
    }
}, params) => ({
    announcements,
    contactText,
    menuItems: menuItems.map((item) => HeaderMenuItem(item, params)),
    showAnnouncementsOnAllPages,
    topBarMenuItems: topBarMenuItems.map((item) => Link(item, params)),
})

export default Header
