import Link from './Link'

const LinkGroup = ({
    _uid,
    title,
    links,
}, params) => ({
    _uid,
    title,
    links: links.map((link) => Link(link, params)),
})

export default LinkGroup
