import { omitEmptyValues } from '@connections/utils'
import DayItem from './DayItem'

const DayByDaySection = ({
    dayItems,
    ...props
}, params) => omitEmptyValues({
    ...props,
    dayItems: dayItems.map((dayItem) => DayItem(dayItem, params)),
})

export default DayByDaySection
