import { omitEmptyValues } from '@connections/utils'
import Activity from './Activity'
import Break from './Break'
import Destination from './Destination'
import Hotel from './Hotel'
import Tour from './Tour'
import Page from './Page'

const Card = ({
    _uid,
    _editable,
    component,
    tag,
    link,
    image,
    video,
    stars,
    break: breakItem,
    destination,
    hotel,
    tour,
    page,
    activity,
    blogPost,
    expert,
    shop,
    airline,
    icons,
    featuredImage,
    buttonText,
    columnWidth,
    hideGradient,
    openInNewTab,
    subject,
    title,
    text,
    buttons,
    alignContent,
    icon,
    description,
    departureCodes,
    destinations,
    full_slug,
}) => omitEmptyValues({
    _uid,
    _editable,
    component,
    tag,
    link,
    image,
    video,
    stars,
    break: Break(breakItem),
    destination: Destination(destination),
    hotel: Hotel(hotel),
    tour: Tour(tour),
    activity: Activity(activity),
    page: Page(page),
    blogPost,
    expert,
    shop,
    airline,
    icons,
    featuredImage,
    buttonText,
    columnWidth,
    hideGradient,
    openInNewTab,
    subject,
    title,
    text,
    buttons,
    alignContent,
    icon,
    description,
    departureCodes,
    destinations,
    full_slug,
})

export default Card
