import React from 'react'

const ModalBackground = (props) => (
    <div
        className="fixed inset-0 z-modal bg-black opacity-30 cursor-pointer"
        {...props}
    />
)

export default ModalBackground
