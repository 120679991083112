import { storyblokEditable } from "@storyblok/js";
import type { Component } from "../../storyblokTypes";

export default class ComponentTransformer {
    static transform(content: any, context: any): Component {
        const { component, _uid, _editable } = content;
        const { isPreview } = context;
        return {
            component,
            uid: _uid,
            bridgeAttributes: isPreview
                ? storyblokEditable({ _editable })
                : null,
        };
    }
}
