import { omitEmptyValues } from '@connections/utils'

const Break = (breakItem = null) => {
    if (breakItem === null || breakItem === '') {
        return null
    }
    if (typeof breakItem.content === 'undefined') {
        return breakItem
    }
    const {
        content: {
            tag,
            link,
            headImage,
            title,
            iataCodes,
            description,
            price,
        },
        full_slug,
    } = breakItem
    return {
        content: omitEmptyValues({
            tag,
            link,
            headImage,
            title,
            iataCodes,
            description,
            price,
        }),
        full_slug,
    }
}

export default Break
