import Link from '../components/Link'

const Settings = ({
    content: {
        _uid,
        seo,
        contactEmail,
        contactPhone,
        contactJotFormId,
        termsAndConditionsLink = [],
        insurancePolicyLink = [],
    }
}, params) => ({
    _uid,
    seo,
    contactEmail,
    contactPhone,
    contactJotFormId,
    termsAndConditionsLink: termsAndConditionsLink.length > 0
        ? Link(termsAndConditionsLink[0], params)
        : null,
    insurancePolicyLink: insurancePolicyLink.length > 0
        ? Link(insurancePolicyLink[0], params)
        : null,
})

export default Settings
