import { omitEmptyValues } from '@connections/utils'
import ProgramBlock from './ProgramBlock'

const ProgramSlot = ({
    _uid,
    component,
    timeStart,
    timeEnd,
    language,
    programBlock,
    title,
    description,
}) => omitEmptyValues({
    _uid,
    component,
    timeStart,
    timeEnd,
    language,
    title,
    description,
    programBlock: ProgramBlock(programBlock),
})

export default ProgramSlot
