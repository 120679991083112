import Column from './Column'

const ColumnsSection = ({
    columns,
    ...content
}) => ({
    ...content,
    columns: columns.map(Column),
})

export default ColumnsSection
