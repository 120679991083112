import { omitEmptyValues } from '@connections/utils'

const DayByDayMapSection = ({
    _uid,
    size,
    title,
    spacing,
    anchorId,
    component,
    container,
    initialZoom,
    _editable,
}) => {
    const intialZoomParsed = parseInt(initialZoom, 10)
    const initialZoomMin = Math.min(intialZoomParsed, 22)
    return omitEmptyValues({
        _uid,
        size,
        title,
        spacing,
        anchorId,
        component,
        container,
        initialZoom: initialZoomMin || null,
        _editable,
    })
}

export default DayByDayMapSection
