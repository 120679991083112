import { omitEmptyValues } from '@connections/utils'

const FeaturedCarouselSlide = ({
    image: {
        alt,
        filename,
    },
    tag,
    title,
    subtitle,
    description,
    buttons,
    _editable,
}) => omitEmptyValues({
    image: omitEmptyValues({
        alt,
        filename,
    }),
    tag,
    title,
    subtitle,
    description,
    buttons,
    _editable,
})

export default FeaturedCarouselSlide
