import HeaderCard from './HeaderCard'
import LinkGroup from './LinkGroup'

const HeaderMenuItemDropdown = ({
    cards,
    linkGroups,
}, params) => ({
    cards: cards.map(HeaderCard),
    linkGroups: linkGroups.map((group) => LinkGroup(group, params)),
})

export default HeaderMenuItemDropdown
