import { omitEmptyValues } from '@connections/utils'

const Hotel = (hotel = null) => {
    if (hotel === null || hotel === '') {
        return null
    }
    if (typeof hotel.content === 'undefined') {
        return hotel
    }
    const {
        content: {
            tag,
            stars,
            headImage,
            title,
            iataCodes,
            description,
            link,
            price,
            externalUrl,
        },
        full_slug,
    } = hotel
    return {
        content: omitEmptyValues({
            tag,
            stars,
            headImage,
            title,
            iataCodes,
            description,
            link,
            price,
            externalUrl,
        }),
        full_slug,
    }
}

export default Hotel
